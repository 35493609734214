$black: #272328;
$white: #ffffff;
$ceviRed: #00AEEF;
$ceviBlue: #00AEEF;
$ceviGreen: #66b821;
$darkBackground: #f0f0f0;
$textColor: #666565;
$darkAccentColor: #9b9b9b;
$textColor: $black;

$navigationBackground: #f0f0f0;
$tableColor: $navigationBackground;
$darkAccentColor: #9b9b9b;
$inputColor: $navigationBackground;
$buttonTextColor: #4a4a4a;
